import { ConfigProvider } from 'antd';
import locale from 'antd/locale/id_ID';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';
import ReceiveBroadcast from '../ReceiveBroadcast';
import ability from '../ability/ability';
import { API_URL } from '../config';
import NotFound from '../pages/NotFound';
import store from '../store';
import { logout } from '../store/Auth/action';
import { disconnectSocket, loadSocket } from '../store/Socket/action';
import { routeList } from './routeList';

const RouteRender = () => {
  ability.update(store.getState().auth.permissions);
  const dispatch = useDispatch();
  const { token, permissions, expires_at, user } = useSelector((state) => state.auth);

  const expired = expires_at ? checkExpiredToken(expires_at) : false;
  const isLoggedIn = token !== null && permissions.length > 0 && !expired ? true : false;

  function checkExpiredToken(expires_at) {
    let x = moment();
    let y = moment(expires_at);
    if (x.isAfter(y)) {
      dispatch(logout());
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'Sesi telah habis, silahkan login kembali!!',
      });
    }
    return x.isAfter(y);
  }

  useEffect(() => {
    window.addEventListener('load', scrollToTop);
  }, []);

  // buatkan scoket on connected dan on disconnect setelah component di mount
  useEffect(() => {
    const socket = io(API_URL, { query: { userId: user?.id } });
    socket.on('connect', () => {
      // untuk set socket supaya bisa diakses di manapun
      // trigger ketika connect ke server
      socket.emit('user_connected', { id: user?.id, name: user?.name }, (_response) => {
        dispatch(loadSocket(socket));
      });
    });

    socket.on('disconnect', () => {
      dispatch(disconnectSocket());
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, [dispatch, user]);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Use smooth scrolling behavior
    });
  }

  const element = useRoutes(
    routeList(isLoggedIn).map((route) => {
      if (route.subject && route.actions) {
        if (ability.can(route.actions, route.subject)) {
          return route;
        } else {
          return { ...route, element: <NotFound /> };
        }
      }

      return route;
    })
  );

  return <React.Fragment>{element}</React.Fragment>;
};

export default function RouteComponent() {
  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <ReceiveBroadcast />
        <RouteRender />
      </BrowserRouter>
    </ConfigProvider>
  );
}
