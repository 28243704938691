import CryptoJS from 'crypto-js';
import 'moment/locale/id';
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_PERMISSIONS,
  USER_LOADED,
  USER_LOADING,
  USER_SWITCHED,
} from './type';

const AES_KEY = process.env.REACT_APP_AES_KEY || 'CoverSuperIndonesiaGlobalV2';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  isLoading: false,
  user: (() => {
    try {
      return JSON.parse(localStorage.getItem('user'));
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  })(),
  switched: false,
  permissions: (() => {
    try {
      const encryptedPermissions = localStorage.getItem('permissions');
      if (!encryptedPermissions) return [];
      const decryptedPermissions = CryptoJS.AES.decrypt(encryptedPermissions, AES_KEY).toString(
        CryptoJS.enc.Utf8
      );
      return JSON.parse(decryptedPermissions);
    } catch (error) {
      console.error('Error parsing permissions:', error);
      localStorage.clear();
      window.location.href = '/auth/login';
      return [];
    }
  })(),
};

export default function authReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case USER_LOADING:
      return { ...state, isLoading: true };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: payload,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.meta.token);
      localStorage.setItem('refreshToken', payload.meta.refreshToken);
      localStorage.setItem('expires_at', payload.meta.expires_at);
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user', JSON.stringify(payload.data));
      localStorage.setItem('role', payload.data.role.id);
      localStorage.setItem(
        'permissions',
        CryptoJS.AES.encrypt(JSON.stringify(payload.meta.permissions), AES_KEY)
      );

      return {
        ...state,
        token: payload.meta.token,
        refreshToken: payload.meta.refreshToken,
        expires_at: payload.meta.expires_at,
        isAuthenticated: true,
        isLoading: false,
        user: payload.data,
        permissions: payload.meta.permissions,
      };

    case USER_SWITCHED:
      return {
        ...state,
        switched: payload,
      };

    case SET_PERMISSIONS:
      localStorage.setItem(
        'permissions',
        CryptoJS.AES.encrypt(JSON.stringify(payload.data), AES_KEY)
      );
      return {
        ...state,
        permissions: payload.data,
      };

    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        permissions: [],
        token: null,
        refreshToken: null,
        expires_at: null,
        user: null,
        isLoading: false,
        isAuthenticated: false,
        switched: false,
      };
    default:
      return state;
  }
}
